<template>
  <div id="app">
    <swiper ref="swiper" :options="swiperOptions">
      <swiper-slide v-for="(item, index) in imgList" :key="'swiper' + index">
        <div>
          <img
            :class="'swiper-slide__img'"
            :id="'img' + index"
            :src="item"
            alt=""
          />
        </div>
      </swiper-slide>
      <div
        class="swiper-button-prev"
        @click="buttonPrev"
        slot="button-prev"
      ></div>
      <div
        class="swiper-button-next"
        @click="buttonNext"
        slot="button-next"
      ></div>
    </swiper>
    <div class="swiper gallery-thumbs">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in imgList"
          :key="'thumbs' + index"
          @click="change(index)"
          :class="number == index ? 'cellActive' : ''"
        >
          <div class="swiperImg">
            <img class="swiper-slide__img2" :src="item" alt="" />
          </div>
        </div>
      </div>
      <!-- <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next" id="myNext"></div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  props: {
    imgList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      number: 0,
      swiperOptions: {
        spaceBetween: 10, //大轮播图间距
        navigation: {
          //大轮播图的切图按钮，如果想放在轮播图中可以看swiper官方文档
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        thumbs: {
          swiper: {
            el: ".gallery-thumbs",
            slidesPerView: 4, //缩略图显示张数
            slidesPerGroup: 4, //缩略图每组切图张数
            spaceBetween: 10, //缩略图间距
            slideToClickedSlide: true,
            navigation: {
              //缩略图的切图按钮，如果想放在轮播图中可以看swiper官方文档
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          },
          autoScrollOffset: true,
        },
      },
    };
  },
  mounted() {},
  watch: {
    number: {
      handler(n, o) {
        setTimeout(() => {
          var img = document.getElementById("img" + n); // 选择页面上的某个图像元素
          var originalWidth = img.naturalWidth; // 获取图像的原始宽度
          var originalHeight = img.naturalHeight; // 获取图像的原始高度
          var imageSize = img.naturalWidth * img.naturalHeight * 4; // 假设每个像素占4字节
          var imageSizeInMB = imageSize / (1024 * 1024);
          let imgInfo = {
            width: originalWidth,
            height: originalHeight,
            size: imageSizeInMB.toFixed(2) + "MB",
          };
          this.$emit("imgInfo", imgInfo);
        }, 500);
      },
      immediate: true,
    },
  },
  methods: {
    buttonNext() {
      this.number = this.number + 1;
      if (this.number == 4) {
        document.getElementById("myNext").click();
      }
    },
    buttonPrev() {
      this.number = this.number - 1;
    },
    change(index) {
      this.number = index;
    },
    dowoloadImg() {
      let _this = this;
      const anchor = document.createElement("a");
      // 创建一个 XMLHttpRequest 对象来获取图片数据
      const xhr = new XMLHttpRequest();
      xhr.open("GET", _this.imgList[_this.number], true);
      xhr.responseType = "blob"; // 设置响应类型为 blob

      xhr.onload = function () {
        if (xhr.status === 200) {
          const blob = xhr.response; // 获取返回的 blob 数据
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(blob); // 创建一个临时的 URL

          anchor.href = imageUrl;
          anchor.download = _this.imgList[_this.number]; // 指定下载文件名
          document.body.appendChild(anchor); // 将 <a> 标签添加到页面中（某些浏览器需要）
          anchor.click(); // 触发点击事件以开始下载
          document.body.removeChild(anchor); // 下载完成后移除 <a> 标签
          window.URL.revokeObjectURL(imageUrl); // 释放之前创建的对象 URL
        }
      };

      xhr.onerror = function () {
        console.error("请求失败");
      };

      xhr.send();
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  width: 100%;
  margin: 2.7778vh auto 0;
}
.swiper-slide {
  width: 10.9375vw;
  &__img {
    width: 100%;
    height: 31.25vw;
    object-fit: cover;
  }
}
.swiper {
  &.gallery-thumbs {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    height: 13.8889vh;
    padding: 0.9259vh 0;
    &::before,
    &::after {
      z-index: 2;
      position: absolute;
      top: 0;
      content: "";
      height: 13.8889vh;
      width: 0;
      background-color: rgb(105, 105, 105);
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 50% !important;
      transform: translateY(-50%) !important;
      width: 2.0833vw;
      height: 9.2593vh;
      margin-top: 0 !important;
      border-radius: 0.4167vw;
      color: #f3f4f4;
      background-color: rgba(0, 0, 0, 0.7);
      &::after {
        font-size: 2rem;
      }
    }
  }
  // &.gallery-thumbs .swiper-slide {
  //   opacity: 0.4;
  //   overflow: hidden;
  // }
  // &.gallery-thumbs .swiper-slide-thumb-active {
  //   border: 2px solid red;
  // }
}
.swiperImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-slide__img2 {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.cellActive {
  border: 0.1042vw solid #f9680d;
}
.swiper-wrapper {
  gap: 0.9375vw;
}
::v-deep .swiper-button-prev {
  width: 3.125vw;
  height: 3.125vw;
  background-size: 3.125vw 3.125vw;
  background-image: url("../../../assets/img/renyiOverview/left.png");
}
::v-deep .swiper-button-next {
  width: 3.125vw;
  height: 3.125vw;
  background-size: 3.125vw 3.125vw;
  background-image: url("../../../assets/img/renyiOverview/right.png");
}
</style>
