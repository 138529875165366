<template>
  <div class="all-box">
    <img class="bg-top" src="@/assets/img/performance/bg-4.png" />
    <img class="bg-bottom" src="@/assets/img/performance/bg-5.png" />
    <div class="foun-top">
      <Breadcrumb class="bread breadcrumb-1400" :list="list"></Breadcrumb>
    </div>
    <div class="foun-box">
      <div class="left">
        <div class="title">{{ detailList.title }}</div>
        <div class="detail">
          <div class="time">{{ detailList.createTime }}</div>
          <div class="line"></div>
          <div class="author">{{ detailList.author }}</div>
          <div class="line"></div>
          <div class="source">{{ detailList.source }}</div>
        </div>
        <div class="content">
          <photoBanner
            ref="photoBanner"
            :imgList="imgList"
            @imgInfo="getImgInfo"
          ></photoBanner>
        </div>
      </div>
      <div class="right">
        <div class="downloadBtn" @click="downloadImg">下载原图</div>
        <div class="right_content">
          <div class="content_item">分类：{{ detailList.tag }}</div>
          <div class="content_item">
            尺寸：{{ imgInfo.width ? `${imgInfo.width} x ${imgInfo.height}` : '' }}
          </div>
          <div class="content_item">大小：{{ imgInfo.size }}</div>
          <div class="content_item">上传时间：{{ detailList.createTime.slice(0, 10) }}</div>
          <div class="describe">
            网站声明：本站所有影像资源仅供欣赏与参考，禁止商用。
          </div>
        </div>
      </div>
    </div>
    <PageFooter />
  </div>
</template>
<script>
import Breadcrumb from "../../../components/pc/Breadcrumb.vue";
import { getPortalDetail } from "@/service/ArtsInformation";
import photoBanner from "./photoBanner.vue";
export default {
  components: {
    Breadcrumb,
    photoBanner,
  },
  data() {
    return {
      detailList: {},
      list: ["人艺资讯", "星光大合影"],
      imgList: [],
      imgInfo: {},
    };
  },
  created() {
    this.getList(this.$route.query.id);
  },
  mounted() {},
  methods: {
    async getList(id) {
      const res = await getPortalDetail(id);
      this.detailList = res.data;
      this.imgList = JSON.parse(this.detailList.attachmentInfo).map(
        (item) => item.linkUrl
      );
    },
    downloadImg() {
      this.$refs.photoBanner.dowoloadImg();
    },
    getImgInfo(info) {
      this.imgInfo = info;
    },
  },
};
</script>
<style scoped lang="less">
.downloadBtn {
  width: 15.625vw;
  height: 4.6296vh;
  background-color: #f9680d;
  margin: 1.5625vw auto;
  border-radius: 0.3125vw;
  color: #ffffff;
  text-align: center;
  line-height: 4.6296vh;
  font-size: 1.0417vw;
  font-weight: 600;
  cursor: pointer;
}
.right_content {
  width: 15.625vw;
  margin: 1.5625vw auto;
  .content_item {
    font-size: .8333vw;
    margin-bottom: 0.9259vh;
  }
  .describe {
    font-size: .8333vw;
    margin-top: 1.5625vw;
  }
}

.all-box {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f8f8fa;
  margin-top: -1.0417vw;
  z-index: 2;

  .bg-top {
    position: absolute;
    z-index: -1;
  }

  .bg-bottom {
    position: absolute;
    right: 0;
    bottom: 31.25vw;
    z-index: -1;
  }

  .foun-top {
    width: 41.6667vw;
    height: 0.7292vw;
    margin-top: 1.0417vw;
    margin-left: 13.0208vw;

    .bread {
      padding-top: 1.0417vw;
    }
  }

  .foun-box {
    display: flex;
    // width: 1800px;
    margin: 2.0833vw 13.0208vw 2.6042vw 13.0208vw;
    min-height: 52.0833vw;
    height: auto;
    display: flex;
    position: relative;
    /* 添加定位属性 */
    z-index: 5;

    .left {
      width: 52.0833vw;
      background-color: #ffffff;

      .title {
        font-weight: 700;
        font-size: 1.1979vw;
        text-align: left;
        margin-top: 1.8229vw;
        margin-left: 2.6042vw;
        margin-right: 2.6042vw;
      }

      .detail {
        display: flex;
        font-size: 0.7292vw;
        height: 0.7292vw;
        line-height: 0.7292vw;
        margin-left: 2.6042vw;
        margin-top: 0.7813vw;
        color: #666;

        .line {
          height: 0.7292vw;
          width: 0.1042vw;
          background: #666;
          margin-left: 0.2604vw;
          margin-right: 0.2604vw;
        }
      }

      .content {
        font-size: 0.8333vw;
        margin-left: 2.6042vw;
        margin-right: 2.6042vw;
      }
    }

    .right {
      width: 20.8333vw;
      margin-left: 2.6042vw;
      height: 26.0417vw;
      background-color: #ffffff;

      .title {
        text-align: left;
        margin-top: 1.3021vw;
        margin-left: 1.3021vw;
        font-size: 0.8333vw;
        font-weight: 700;
      }

      .content {
        margin-top: 1.3021vw;
        margin-left: 1.3021vw;
        margin-right: 1.3021vw;
        text-align: justify;
        cursor: pointer;

        .name {
          font-weight: 700;
          font-size: 0.8333vw;
        }

        .time {
          margin-top: 0.5208vw;
          font-size: 0.8333vw;
        }
      }
    }
  }
}
</style>
